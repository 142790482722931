import styled from "styled-components"

const Styles = styled.div`
  padding-top: 1rem;

  .search-logo {
    position: absolute;
    padding: 7px;
    padding-top: 5px;
    width: 3rem;
  }

  input {
    padding-left: 3rem;
  }

  table {
    width: 100%;
    margin-top: 2rem;

    th {
      height: 3rem;
      background-color: #fefce8;
      padding: 1rem;
    }

    td {
      padding: 0.75rem 1rem;
    }

    tr {
      height: 2rem;
    }

    tr:nth-child(even) {
      background-color: #fefce8;
    }

    td:first-child {
      width: 3rem;
    }

    .jumlah-peserta {
      width: 12rem;
    }
  }

  .profile-pic-sm {
    width: 2.5rem;
    border-radius: 50%;
    height: 2.5rem;
    margin-right: 1rem;
  }

  .name {
    font-weight: 500;
  }

  .email {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #1f2937;
  }

  .spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
  }
`

export default Styles

import React from "react"
import styled from "styled-components"
import Smallnav from "../Reusable/Smallnav"

const DashboardStyles = styled.div`
  padding-top: 40px;

  .asd-smallnav > div:first-child {
    justify-content: center;

    a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .asd-smallnav > .smallnav-border-gray {
    border-bottom: none;
  }

  @media only screen and (max-width: 700px) {
    .asd-smallnav > div:first-child {
      justify-content: start;
    }
  }
`

const DashboardTutorComponent = ({ chosen, children }) => {
  return (
    <DashboardStyles className="layout">
      <Smallnav
        navList={[
          // {
          //   text: "Summary",
          //   url: "/dashboard/tutor",
          // },
          {
            text: "Audience",
            url: "/dashboard/tutor",
          },
          {
            text: "Dompet",
            url: "/dashboard/tutor/balance",
          },
          {
            text: "Course Review",
            url: "/dashboard/tutor/course",
          },
        ]}
        link
        chosen={chosen}
      >
        <div>{children}</div>
      </Smallnav>
    </DashboardStyles>
  )
}

export default DashboardTutorComponent

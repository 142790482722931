import React from "react"
import Audience from "../../../../components/DashboardTutor/Audience"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import * as sessionSelectors from "../../../../redux/slices/session/selectors"
import { connect } from "react-redux"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const DashboardTutorAudience = ({ user }) => {
  return (
    <Layout>
      <SEO title="Audience" />
      <Audience user={user} />
    </Layout>
  )
}

export default withConnect(DashboardTutorAudience)

import React, { useEffect, useState } from "react"
import DashboardTutorComponent from ".."
import { getCoursesByTutor } from "../../../services/course"
import Styles from "./styles"
import Input from "../../Reusable/Input"
import { Link } from "gatsby"
import searchLogo from "../../../images/common/search.png"

const Audience = ({ user }) => {
  const [courses, setCourses] = useState([])
  const [options, setOptions] = useState({
    judul: "",
  })

  useEffect(() => {
    if (user?.id) {
      getCoursesByTutor(parseInt(user.id)).then(response => {
        setCourses(response?.data?.results)
      })
    }
  }, [user])

  return (
    <DashboardTutorComponent chosen={0}>
      <Styles>
        <h3>Daftar kursus yang kamu ajar</h3>
        <br />
        <div>
          <img className="search-logo" src={searchLogo} />
          <Input
            placeholder="Filter kursus berdasarkan judul"
            onChange={e => setOptions({ ...options, judul: e.target.value })}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Judul</th>
              <th>Jumlah peserta</th>
            </tr>
          </thead>
          <tbody>
            {courses?.length > 0 &&
              courses
                .filter(course =>
                  course.title.toLowerCase().includes(options.judul)
                )
                .map((course, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/dashboard/tutor/audience/detail?id=${course.id}`}
                        >
                          {course.title}
                        </Link>
                      </td>
                      <td className="jumlah-peserta">
                        {course.jumlah_peserta}
                      </td>
                    </tr>
                  )
                })}
          </tbody>
        </table>
      </Styles>
    </DashboardTutorComponent>
  )
}

export default Audience
